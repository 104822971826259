import React from "react"
import Layout from "../components/layout"
import ContactForm from "../components/contact-form"
import { graphql } from 'gatsby'

export default function ContactPage({ data }) {
  return (
    <Layout location="/contact" title="Contact">
      <h1>Contact Alan</h1>
      <ContactForm identity={data.site.siteMetadata.IdentityPoolId} topic={data.site.siteMetadata.TopicArn} />
    </Layout>
  )
}

export const query = graphql`
query AWSCognitoData{
  site {
    siteMetadata {
      IdentityPoolId
      TopicArn
    }
  }
}
`
