import React from "react";
import { useForm } from "react-hook-form";
import AWS from 'aws-sdk';


export default function ContactForm({ identity, topic }) {
  const { register, handleSubmit, errors, reset } = useForm();
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [showFailure, setShowFailure] = React.useState(false)
  const succeeded = () => {
    reset({})
    setShowSuccess(true)
    setShowFailure(false)
  }
  const failed = () => {
    setShowSuccess(false)
    setShowFailure(true)
  }
  AWS.config.region = 'us-east-1';
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: identity
  });

  const onSubmit = data => {
    var sns = new AWS.SNS();
    var params = {
      Message: `
Message from: ${data.email}

------------------

${data.message}

------------------
`,
      Subject: `Web site enquiry: ${data.subject}`,
      TopicArn: topic
    };
    const prom = sns.publish(params).promise()
    prom.then(
      (data) => succeeded()
    ).catch(
      (err) => failed()
    )
  }

  return (
    <div className="contact-form">
      {showSuccess === true && <div className="message success">Message sent successfully</div>}
      {showFailure === true && <div className="message failed">Failed to send message</div>}
    <form onSubmit={handleSubmit(onSubmit)}>
      <label className="layout-row"><span>Reply-to email:<sup>*</sup></span>
        <input
          name="email"
          type="email"
          defaultValue=""
          className="focus:outline-black px-2"
          ref={register({ required: true, pattern: /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/ }) }
        />
      </label>
      {errors.email?.type === "required" && <div className="text-center text-red-700">Please enter an email</div>}        
      {errors.email?.type === "pattern" && <div className="text-center text-red-700">Please enter a valid email address</div>}        
      <label className="layout-row"><span>Subject:<sup>*</sup></span>
        <input
          name="subject"
          type="text"
          defaultValue=""
          className="focus:outline-black px-2"
          ref={register({ required: true })}
        />
      </label>
      {errors.subject && <div className="text-center text-red-700">Subject is required</div>}
      <label className="layout-column">
        <span>Message:</span>
        <textarea
          name="message"
          className="focus:outline-black px-2"
          defaultValue=""
          ref={register}
        />
      </label>
      <button className="focus:outline-black px-2" type="submit">Send message</button>
    </form>
    </div>
  )
}
